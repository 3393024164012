import React from "react";
import { PropTypes } from "prop-types";
import { Cell, KeyIndicator } from "@wingmate/graphs";
import { AnalyticsUserAzureIcon } from "@wingmate/graphics";

export function LeadsGeneratedCell({ leads }) {
  const displayValue = leads?.current || 0;
  const previousValue = leads?.previous || 0;

  let trendPercentage = 0;
  if (previousValue > 0) {
    trendPercentage = (displayValue / previousValue - 1) * 100;
  }

  return (
    <Cell
      rowspan={1}
      colspan={1}
      icon={<AnalyticsUserAzureIcon width={40} height={40} />}
      color="#E9EFFF"
    >
      <KeyIndicator
        title="Generated"
        value={`${displayValue} Leads`}
        trendValue={`${previousValue} Leads`}
        trendPercentage={trendPercentage}
        trendLabel="Previous"
      />
    </Cell>
  );
}

LeadsGeneratedCell.propTypes = {
  leads: PropTypes.shape({
    current: PropTypes.number,
    previous: PropTypes.number,
  }),
};

export default LeadsGeneratedCell;
