/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  // returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
import moment from "moment";

export function dateAdd(date, interval, units) {
  if (!(date instanceof Date)) return undefined;
  let ret = new Date(date); // Don't change original date
  const checkRollover = function () {
    if (ret.getDate() !== date.getDate()) ret.setDate(0);
  };
  switch (String(interval).toLowerCase()) {
    case "year":
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case "quarter":
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case "month":
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case "week":
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case "day":
      ret.setDate(ret.getDate() + units);
      break;
    case "hour":
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case "minute":
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case "second":
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      ret = undefined;
      break;
  }
  return ret;
}

export function leadIdentifier(campaign, leadId) {
  if (campaign && campaign.leadLabel) {
    return `${campaign.leadLabel} #${leadId}`;
  }
  return `Lead #${leadId}`;
}

export function getBaseURL() {
  return window.href;
}

export function getURL(uri) {
  return `${getBaseURL()}${uri}`;
}

export function formatMoney(number) {
  // from https://stackoverflow.com/a/14428340/1001980
  return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function formatDateByYear(date) {
  let formattedDate;
  if (moment(date).year() === moment().year()) {
    formattedDate = moment(date).format("MMM D");
  } else {
    formattedDate = moment(date).format("MMM D YYYY");
  }
  return formattedDate;
}

export function formatDateByMonthDayYear(date) {
  return moment(date).format("MMM D, YYYY");
}

export function formatDateWithTime(date) {
  return moment(date).format("MMM D YYYY, h:mm a");
}

export function formatMonthDayTime(date) {
  return moment(date).format("MMM DD, h:mm a");
}

export function formatDateLongWithTime(date) {
  const formattedDate = moment(date).format("MMMM D, YYYY");
  const formattedTime = moment(date).format("h:mm a");
  return formattedDate.concat(" at ").concat(formattedTime);
}

export function getHours(time) {
  const hours = parseInt(time.split(":")[0], 10);
  return hours;
}

export function getMinutes(time) {
  const minutes = parseInt(time.split(":")[1], 10);
  return minutes;
}

export function combineDateAndTime(date, time) {
  let newDate = moment(date);
  if (time) {
    newDate = newDate.set({ hour: getHours(time), minute: getMinutes(time) });
  }
  newDate = new Date(newDate);
  return newDate;
}

export function removeItemFromArray(arr, value) {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      i += 1;
    }
  }
  return arr;
}

export function searchItems(items, searchTerm) {
  if (Array.isArray(items)) {
    return items.filter((item) =>
      item.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  return null;
}

export function now() {
  return moment().format();
}

export function sameElements(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }
      return false;
    });
  }
  return false;
}

export function isArrayEmpty(value) {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return true;
    }
    return false;
  }
  return false;
}

export const alphabeticallySort = (array, field) => {
  return array.sort((a, b) => {
    if (a.attributes[field] < b.attributes[field]) {
      return -1;
    }
    if (a.attributes[field] > b.attributes[field]) {
      return 1;
    }
    return 0;
  });
};

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function encodeDictionaryToUrlParams(params) {
  const queryParts = [];

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryParts.push(
          `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`
        );
      });
    } else {
      queryParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  });

  return queryParts.join("&");
}

export function removeUndefinedValues(dict) {
  Object.keys(dict).forEach((key) => {
    if (dict[key] === undefined || dict[key] === null) {
      delete dict[key];
    }
  });

  return dict;
}

export function isTooDark(color) {
  // if the given color is too dark as a background for black text,
  // the function will return true
  function hexToRgb(hex) {
    if (
      typeof hex !== "string" ||
      !hex.startsWith("#") ||
      (hex.length !== 4 && hex.length !== 7)
    ) {
      return null;
    }
    if (hex.length === 4) {
      hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }

  function parseRgb(rgb) {
    if (
      typeof rgb !== "string" ||
      !rgb.match(/^rgba?\((\d+,\s?){2}\d+(,\s?\d+(\.\d+)?)?\)$/)
    ) {
      return null;
    }
    const values = rgb.match(/\d+/g).slice(0, 3).map(Number);
    if (values.some((v) => v < 0 || v > 255)) {
      return null;
    }
    return values;
  }

  function getLuminance([r, g, b]) {
    if (![r, g, b].every((v) => typeof v === "number" && v >= 0 && v <= 255)) {
      return null;
    }
    const [rs, gs, bs] = [r, g, b].map((c) => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4;
    });
    return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
  }

  try {
    let rgb;

    if (typeof color === "string") {
      if (color.startsWith("#")) {
        rgb = hexToRgb(color);
      } else if (color.startsWith("rgb")) {
        rgb = parseRgb(color);
      } else {
        return null;
      }
    } else if (Array.isArray(color) && color.length >= 3) {
      rgb = color.slice(0, 3);
    } else {
      return null;
    }

    if (!rgb) {
      return null;
    }

    const luminance = getLuminance(rgb);

    if (luminance === null) {
      return null;
    }

    return luminance < 0.5;
  } catch {
    return null;
  }
}
