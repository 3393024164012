import React from "react";
import { Flex } from "@wingmate/toolkit";
import { Spinner } from "../../Filters/Spinner";

import "./DrawerSpinner.scss";

export function DrawerSpinner() {
  return (
    <Flex justify="center" align="center" className="Spinner__Container">
      <Spinner />
    </Flex>
  );
}
export default DrawerSpinner;
