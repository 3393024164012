import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { SankeyChart, Cell } from "@wingmate/graphs";
import { Text } from "@wingmate/toolkit";
import { AnalyticsHandshakeIcon } from "@wingmate/graphics";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadDetailsDrawer } from "./LeadDetailsDrawer/LeadDetailsDrawer";
import { DrawerHeaderWithNumber } from "./LeadDetailsDrawer/DrawerHeaderWithNumber";

import "./StatusChangeCell.scss";

export function StatusChangeCell({ changes, filters }) {
  const [drawerData, setDrawerData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayNumber, setDisplayNumber] = useState(null);

  const getDrawerData = async (params) => {
    setIsLoading(true);

    try {
      const response = await makeFormattedRequest(
        `crushr/analytics/lead_details.json?`,
        params
      );

      setDrawerData(response.data);
      const numberOfLeads = response.data.length;
      setDisplayNumber(numberOfLeads);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
    setIsLoading(false);
  };

  const showData = (target) => {
    if (target.source) return;
    const filtersForRequest = filters;
    filtersForRequest.lbCampaignId = undefined;
    filtersForRequest.status = target.status;
    getDrawerData(filtersForRequest);

    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Cell
        rowspan={1}
        colspan={2}
        position={{ col: 1, row: 2 }}
        icon={<AnalyticsHandshakeIcon width={44} height={44} />}
        color="#DAF3FE"
      >
        <Text
          type="P"
          weight="Medium"
          className="KeyIndicator__Title AnalyticsTitle"
        >
          Status Changes
        </Text>
        <br />
        <br />
        {changes && (
          <SankeyChart
            data={changes}
            height={300}
            onClick={(clickedNode) => showData(clickedNode)}
          />
        )}
      </Cell>
      <LeadDetailsDrawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        title={
          <DrawerHeaderWithNumber
            title="Status Changes"
            number={displayNumber}
          />
        }
        data={drawerData}
        isLoading={isLoading}
        groupBy="previous_status"
        labelNodes={changes?.nodes}
      />
    </>
  );
}

StatusChangeCell.propTypes = {
  changes: PropTypes.object,
  filters: PropTypes.object,
};

export default StatusChangeCell;
