import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { Button, Flex } from "@wingmate/toolkit";
import { Spinner } from "./Spinner";
import { DateFilter } from "../../common/DateFilter/DateFilter";

import "./Filters.scss";

export function Filters({ campaigns, defaultFilters, isLoading, onSubmit }) {
  const [filters, setFilters] = useState(defaultFilters);

  const modifyFilters = (newFilters) => {
    setFilters({
      ...filters,
      ...newFilters,
      lbCampaignId: undefined,
    });
  };

  const onUserGroupingChange = (values, options) => {
    const campaignIds = options
      .filter((option) => option.type === "campaign")
      .map((option) => option.id);

    const regions = options
      .filter((option) => option.type === "region")
      .map((option) => option.id);

    modifyFilters({ regions, campaignIds });
  };

  return (
    <Flex className="CrushrFilters" align="center" gap={8}>
      {isLoading && <Spinner />}
      <DateFilter
        defaultInterval={filters.interval}
        defaultEndDate={filters.endDate}
        defaultStartDate={filters.startDate}
        onChange={modifyFilters}
      />
      <Select
        mode="multiple"
        defaultValue={filters.campaignIds}
        placeholder="Search..."
        onChange={onUserGroupingChange}
        showSearch
        filterOption={(input, option) =>
          option.label.props.children
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        id="campaigns"
        options={[
          {
            label: <span>Regions</span>,
            title: "Regions",
            options: [
              {
                label: <span>West</span>,
                type: "region",
                value: "west",
                id: "west",
              },
              {
                label: <span>Mid West</span>,
                type: "region",
                value: "midwest",
                id: "midwest",
              },
              {
                label: <span>North East</span>,
                type: "region",
                value: "northeast",
                id: "northeast",
              },
              {
                label: <span>South</span>,
                type: "region",
                value: "south",
                id: "south",
              },
            ],
          },
          {
            label: <span>Pipelines</span>,
            title: "Pipelines",
            options: campaigns.map((campaign) => ({
              label: <span>{campaign.attributes.name}</span>,
              value: `campaign_${campaign.id}`,
              type: "campaign",
              id: campaign.id,
            })),
          },
        ]}
      />
      <Button type="filled" onClick={() => onSubmit(filters)} primary>
        Submit
      </Button>
    </Flex>
  );
}

Filters.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  defaultFilters: PropTypes.shape({
    campaignIds: PropTypes.arrayOf(PropTypes.string),
    endDate: PropTypes.string,
    interval: PropTypes.string,
    startDate: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Filters;
