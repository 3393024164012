export const FUNNEL_ENDPOINT = "/dashboard.json";
export const HOME_ENDPOINT = "/";
export const SEARCH_LEADS_ENDPOINT = "/leads.json";
export const UPDATE_BULK_LEAD_ENDPOINT = "/app/v1.0/leads/bulk.json";
export const SIGN_OUT_ENDPOINT = "/users/sign_out";
export const SIGN_IN_ENDPOINT = "/users/sign_in";

export const CREATE_QUOTE_ATTACHMENT_ENDPOINT = (quoteId, format = "") =>
  `/app/v1.0/quotes/${quoteId}/attachment${format !== "" ? `.${format}` : ""}`;

export const ATTACHMENT_ENDPOINT = (id) => `/app/v1.0/attachments/${id}`;

export const LEAD_ATTACHMENTS_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/attachments`;

export const NEW_LEAD_ATTACHMENT_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/attachments/new`;

export const CREATE_LEAD_ATTACHMENT_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/attachments`;

export const NEW_IMAGE_ENDPOINT = "/app/v1.0/leads/images/new";

export const CAMPAIGN_ENDPOINT = (id) => `/app/v1.0/campaigns/${id}`;

export const CAMPAIGN_USERS_ENDPOINT = (id) => `/campaigns/${id}/users.json`;

export const DASHBOARD_ENDPOINT = (view) => `/dashboard?view=${view}`;

export const CREATE_CAMPAIGN_LEAD_ENDPOINT = (campaignId) =>
  `/app/v1.0/campaigns/${campaignId}/leads`;

export const LEAD_ENDPOINT = (id, format = "") =>
  `/leads/${id}${format !== "" ? `.${format}` : ""}`;

export const LEAD_ENDPOINT_REACT = (id, format = "") =>
  `/react/leads/${id}${format !== "" ? `.${format}` : ""}`;

export const LEAD_OWNER_ENDPOINT = (id) => `/leads/${id}/owner.json`;

export const LEAD_CUSTOM_FIELDS_ENDPOINT = (leadId, customFieldId) =>
  `/app/v1.0/leads/${leadId}/custom_fields/${customFieldId}.json`;

export const LEADS_ENDPOINT = (format = "", filtersEncoded = "") =>
  `/leads${format !== "" ? `.${format}` : ""}${
    filtersEncoded !== "" ? `?${filtersEncoded}` : ""
  }`;

export const LEAD_ENDPOINT_WITH_ANCHOR = (id, anchor = "") =>
  `/leads/${id}#${anchor}`;

export const NEW_LEAD_ENDPOINT = "/mobile/leads/new";

export const LEAD_FORM_NEW_LEAD = (leadFormId) =>
  `/lead_forms/${leadFormId}/leads`;

export const CAMPAIGNS_LEAD_FORM_ENDPOINT = (campaignId, leadFormId) =>
  `/campaigns/${campaignId}/lead_forms/${leadFormId}`;

export const CAMPAIGNS_LEAD_FORM_INDEX = (campaignId) =>
  `/campaigns/${campaignId}/lead_forms/`;

export const REMINDERS_ENDPOINT = "/app/v1.0/reminders";

export const REMINDERS_TODAY_ENDPOINT = "/reminders/today";

export const USER_REMINDERS_CSV_ENDPOINT = (userId) =>
  `/users/${userId}/reminders.csv`;

export const CREATE_LEAD_REMINDER_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/reminders`;

export const CREATE_COMMENT_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/comments`;

export const CAMPAIGN_REMINDERS_ENDPOINT = (campaignId) =>
  `/app/v1.0/campaigns/${campaignId}/reminders`;

export const REMINDER_ENDPOINT = (reminderId) =>
  `/app/v1.0/reminders/${reminderId}.json`;

export const USER_REMINDERS_ENDPOINT = (userId, format = "") =>
  `/users/${userId}/reminders${format !== "" ? `.${format}` : ""}`;

export const USER_REMINDERS_CALENDAR_ENDPOINT = (userId) =>
  `/users/${userId}/reminders/calendar.json`;

export const REMINDERS_CALENDAR_ENDPOINT = "/reminders/calendar";

export const CREATE_LEAD_LINK_ENDPOINT = "/app/v1.0/lead_links";

export const LEAD_CONTACT_ENDPOINT = (leadId, contactId) =>
  `/app/v1.0/leads/${leadId}/contacts/${contactId}`;

export const LEAD_CONTACTS_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/contacts`;

export const LEAD_LINK_ENDPOINT = (linkId) => `/app/v1.0/lead_links/${linkId}`;

export const LEAD_LINKS_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/lead_links`;

export const LEAD_TRACK_ENDPOINT = (leadId, trackId) =>
  `/app/v1.0/leads/${leadId}/pipeline_tracks/${trackId}`;

export const CREATE_LEAD_TRACK_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/pipeline_tracks`;

export const LEAD_ENDPOINT_A1 = (leadId) => `/app/v1.0/leads/${leadId}.json`;

export const CREATE_LEAD_FLAG_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/flags`;

export const CREATE_LEAD_USER_TRANSACTION_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/user_transactions`;

export const LEAD_ACTION_ENDPOINT = (leadId) =>
  `/app/v1.0/leads/${leadId}/actions`;

export const UPDATE_COMMENT_ENDPOINT = (leadId, commentId) =>
  `/app/v1.0/leads/${leadId}/comments/${commentId}`;

export const UPDATE_REMINDER_ENDPOINT = (reminderId) =>
  `/app/v1.0/reminders/${reminderId}`;

export const REMINDER_ICS_ENDPOINT = (reminderId) =>
  `/app/v1.0/reminders/${reminderId}.ics`;

export const MERGE_LEAD_ENDPOINT = (leadId) => `/leads/${leadId}/merge`;

export const USER_CAMPAIGNS_ENDPOINT = (userId) =>
  `/app/v1.0/users/${userId}/campaigns`;
