import React from "react";
import { Drawer } from "@wingmate/toolkit";
import PropTypes from "prop-types";
import { LeadListItem } from "./LeadListItem";
import { DrawerSpinner } from "./DrawerSpinner";
import { NoData } from "./NoData";
import { LeadGroup } from "./LeadGroup";
import "./LeadDetailsDrawer.scss";

export function LeadDetailsDrawer({
  data,
  groupBy,
  isLoading = true,
  labelNodes,
  onClose,
  open,
  title,
}) {
  const clearHistory = async () => {
    await window.history.back();
    await window.history.replaceState(null, "", window.location.href);
    await window.history.pushState(null, "", window.location.href);
  };

  const drawerClose = () => {
    onClose();
    clearHistory();
  };

  const getStatusLabel = (key) => {
    const label = labelNodes.find((node) => node.status === key);
    return label.id;
  };
  const groupLeads = (leads, field) => {
    const groupedMap = leads.reduce((groupedLeads, currentLead) => {
      const groupField = currentLead[field];
      if (!groupedLeads[groupField]) {
        groupedLeads[groupField] = [];
      }
      groupedLeads[groupField].push(currentLead);
      return groupedLeads;
    }, {});
    return groupedMap;
  };

  const renderData = () => {
    if (groupBy) {
      const groupedData = groupLeads(data, groupBy);

      const groups = Object.values(groupedData);

      if (groups.length) {
        return groups.map((group) => {
          const groupTitle = `${getStatusLabel(group[0].previous_status)} > ${
            group[0].status
          }`;

          return (
            <LeadGroup
              key={groupTitle}
              data={group}
              title={groupTitle}
              status={group[0].status}
            />
          );
        });
      }
    } else if (data.length) {
      return data.map((lead) => (
        <LeadListItem
          key={lead.id}
          id={lead.id}
          name={lead.name}
          city={lead.city}
          address={lead.address}
          date={lead.actioned_at}
          health={lead.health}
          imgSrc={lead.image_url || lead.thumbnail}
          owners={lead.owners}
          status={lead.status}
          statusColor={lead.status_color}
          origin={lead.origin_url}
        />
      ));
    }
    return <NoData />;
  };

  return (
    <Drawer onClose={drawerClose} open={open} title={title} width="475px">
      {isLoading ? (
        <DrawerSpinner />
      ) : (
        <ul className="Drawer__List">{renderData()}</ul>
      )}
    </Drawer>
  );
}
export default LeadDetailsDrawer;

LeadDetailsDrawer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      city: PropTypes.string,
      address: PropTypes.string,
      actioned_at: PropTypes.string,
      health: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image_url: PropTypes.string,
      thumbnail: PropTypes.string,
      owners: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          full_name: PropTypes.string,
          profile_image_url: PropTypes.string,
        })
      ),
      status: PropTypes.string,
      status_color: PropTypes.string,
      origin_url: PropTypes.string,
    })
  ),
  labelNodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
  groupBy: PropTypes.string,
};
