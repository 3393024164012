import { encodeDictionaryToUrlParams, removeUndefinedValues } from "./commons";
import { axiosGet } from "../requests/axiosRequests";

export const urlEncodeParams = (data) => {
  const params = new URLSearchParams();

  Object.keys(data).map((key) => {
    const value = data[key];

    if (Array.isArray(data[key])) {
      const arrayKey = `${key}[]`;
      value.map((item) => params.append(arrayKey, item));
    } else {
      params.append(key, value);
    }
    return key;
  });

  return params.toString();
};

export const setBrowserUrl = (params) => {
  if (window.history.replaceState) {
    const encodedParams = urlEncodeParams(params);

    const newUrl = `${window.location.pathname}?${encodedParams}`;
    window.history.pushState("", null, newUrl);
  }
};

export const makeFormattedRequest = async (url, params) => {
  const cleanedParams = removeUndefinedValues(params);
  setBrowserUrl(cleanedParams);

  const payload = {
    interval: params.interval,
    campaign_ids: params.campaignIds,
    regions: params.regions,
    start_date: params.startDate,
    end_date: params.endDate,
    status: params.status,
    lb_campaign_id: params.lbCampaignId,
  };

  const cleanedPayload = removeUndefinedValues(payload);
  const encodedPayload = encodeDictionaryToUrlParams(cleanedPayload);
  const endpoint = `${url}${encodedPayload}`;

  const responseData = await axiosGet(endpoint);
  return responseData;
};

export default {
  urlEncodeParams,
};
