import React from "react";
import { Text, Tag, AvatarGroup, Avatar, Flex, Image } from "@wingmate/toolkit";
import PropTypes from "prop-types";
import { HeartbeatIcon, PinIcon } from "@wingmate/graphics";
import { formatDateByMonthDayYear, isTooDark } from "../../../../utils/commons";
import "./LeadListItem.scss";

export function LeadListItem({
  address,
  city,
  date,
  health,
  id,
  imgSrc,
  name = "",
  origin,
  owners = [],
  status,
  statusColor,
}) {
  const monthDay = formatDateByMonthDayYear(date).slice(0, 5);
  const nameCatch = name || `Lead #${id}`;

  const leadUrl = `${origin}/leads/${id}`;
  const goToLeadPage = () => {
    window.open(leadUrl, "_blank", "noopener,noreferrer");
  };

  const addressText = () => {
    let addressString;
    let textComponent;
    if (city && address) {
      addressString = `${address}, ${city}`;
    } else if (city && !address) {
      addressString = city;
    } else if (address && !city) {
      addressString = address;
    }
    if (addressString) {
      textComponent = (
        <Text>
          <PinIcon className="ListItem__Info__Pin" height="16px" width="10px" />
          {addressString}
        </Text>
      );
    }
    return textComponent;
  };

  return (
    <Flex component="li" className="ListItem" onClick={goToLeadPage}>
      <Image className="ListItem__Image" src={imgSrc} alt={`${name} lead`} />
      <Flex vertical className="ListItem__Container">
        <Flex className="ListItem__Info" gap={8}>
          <div className="ListItem__Info__Text">
            <Text weight="Semi" type="P">
              {nameCatch}
            </Text>
            {addressText()}
          </div>
          <AvatarGroup className="ListItem__Info__AvatarGroup">
            {owners.map((owner) => {
              return (
                <Avatar
                  key={owner.full_name}
                  name={owner.full_name}
                  src={owner.profile_image_url}
                />
              );
            })}
          </AvatarGroup>
        </Flex>
        <Flex className="ListItem__Stats">
          {status && (
            <Tag
              className={isTooDark(statusColor) ? "white-text" : null}
              color={statusColor}
            >
              {status}
            </Tag>
          )}
          <span className="ListItem__Stats__Month">
            {monthDay}
            <span className="ListItem__Stats__Pipe"> | </span>
            <HeartbeatIcon
              className={health}
              fill={health}
              height="16"
              width="16"
            />
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LeadListItem;

LeadListItem.propTypes = {
  city: PropTypes.string,
  date: PropTypes.string,
  health: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  origin: PropTypes.string,
  owners: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string,
      profile_image_url: PropTypes.string,
    })
  ),
  status: PropTypes.string,
  address: PropTypes.string,
  statusColor: PropTypes.string,
};
