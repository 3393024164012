import React from "react";
import { Flex, Text } from "@wingmate/toolkit";
import "./DrawerHeaderWithNumber.scss";
import PropTypes from "prop-types";

export function DrawerHeaderWithNumber({ number, title }) {
  const parsedNumber =
    typeof number === "string"
      ? parseInt(number, 10).toLocaleString()
      : number?.toLocaleString();

  return (
    <Flex className="DrawerTitle">
      <Text className="DrawerTitle__Title" type="H5">
        {title}
      </Text>
      <Text className="DrawerTitle__Number" weight="Bold" type="P">
        {parsedNumber}
      </Text>
    </Flex>
  );
}
export default DrawerHeaderWithNumber;

DrawerHeaderWithNumber.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};
