import React, { useState } from "react";
import PropTypes from "prop-types";
import { Cell, PieChart, Legend } from "@wingmate/graphs";
import { Flex } from "@wingmate/toolkit";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadDetailsDrawer } from "./LeadDetailsDrawer/LeadDetailsDrawer";
import { DrawerHeaderWithNumber } from "./LeadDetailsDrawer/DrawerHeaderWithNumber";
import "./StatusCell.scss";

export function StatusCell({ filters, status }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerData, setDrawerData] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [displayNumber, setDisplayNumber] = useState(null);

  const getDrawerData = async (params, statusSelected) => {
    setIsLoading(true);
    try {
      const response = await makeFormattedRequest(
        `crushr/analytics/leads_by_status.json?`,
        params
      );
      setDrawerData(response.data[statusSelected]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
    setIsLoading(false);
  };

  const showData = (target) => {
    const filtersForRequest = filters;
    const title = `${target.data.label} Leads`;
    setDrawerTitle(title);
    setDisplayNumber(target.value);
    getDrawerData(filtersForRequest, target.data.id);
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <>
      <Cell
        className="StatusCell"
        title="Leads by Status"
        rowspan={1}
        colspan={1}
        position={{ col: 3, row: 2 }}
      >
        {status !== undefined && (
          <Flex
            className="StatusCell__ChartContainer"
            gap={8}
            vertical
            justify="space-between"
          >
            <PieChart
              width="320px"
              height="213px"
              data={status}
              margin={{ left: 8, top: 8, bottom: 8, right: 8 }}
              onClick={(section) => showData(section)}
            />
            <Legend gap={8} entries={status} />
          </Flex>
        )}
      </Cell>
      <LeadDetailsDrawer
        title={
          <DrawerHeaderWithNumber title={drawerTitle} number={displayNumber} />
        }
        data={drawerData}
        isLoading={isLoading}
        onClose={onDrawerClose}
        open={isDrawerOpen}
      />
    </>
  );
}

StatusCell.propTypes = {
  status: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
  filters: PropTypes.object,
};

export default StatusCell;
