import React from "react";
import { Text, Flex } from "@wingmate/toolkit";

export function NoData() {
  return (
    <Flex vertical justify="center" align="center">
      <Text>No lead details available.</Text>
    </Flex>
  );
}
export default NoData;
