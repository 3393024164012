import React from "react";
import { PropTypes } from "prop-types";
import { Cell, KeyIndicator } from "@wingmate/graphs";
import { AnalyticsUsersBlueberryIcon } from "@wingmate/graphics";

export function SignUpCell({ signups }) {
  const displayValue = signups?.current || 0;

  const previousValue = signups?.previous || 0;

  let trendPercentage = 0;
  if (previousValue > 0) {
    trendPercentage = (displayValue / previousValue - 1) * 100;
  }

  return (
    <Cell
      rowspan={1}
      colspan={1}
      icon={<AnalyticsUsersBlueberryIcon width={40} height={40} />}
      color="#F3EDFF"
    >
      <KeyIndicator
        title="Sign Ups"
        value={`${displayValue} Users`}
        trendValue={`${previousValue} Users`}
        trendPercentage={trendPercentage}
        trendLabel="Previous"
      />
    </Cell>
  );
}

SignUpCell.propTypes = {
  signups: PropTypes.shape({
    current: PropTypes.number,
    previous: PropTypes.number,
  }),
};

export default SignUpCell;
