import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Cell, PieChart, Legend } from "@wingmate/graphs";
import { AnalyticsDollarGrassIcon } from "@wingmate/graphics";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadDetailsDrawer } from "./LeadDetailsDrawer/LeadDetailsDrawer";
import { DrawerHeaderWithNumber } from "./LeadDetailsDrawer/DrawerHeaderWithNumber";

import "./TopRowPieChartCell.scss";
import "./ClosedWonAndClosedLostCell.scss";

function formatCurrencyLegend(amount) {
  // Ensure the input is a valid number
  if (Number.isNaN(amount)) {
    throw new Error("Input must be a valid number");
  }

  // Format the number as currency
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function ClosedWonAndClosedLostCell({ closed, failed, filters }) {
  const [drawerData, setDrawerData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [displayNumber, setDisplayNumber] = useState(null);

  const closedWon = parseFloat(closed?.current || 0);
  const closedLost = parseFloat(failed?.current || 0);

  const pieData = [
    {
      id: "Closed Won",
      label: "Closed Won",
      value: closedWon,
      color: "rgb(55,217,91)",
    },
    {
      id: "Closed Lost",
      label: "Closed Lost",
      value: closedLost,
      color: "rgb(196,69,54)",
    },
  ];

  const legendData = [
    {
      id: "Closed Won",
      label: "Closed Won",
      value: formatCurrencyLegend(closedWon),
      color: "rgb(55,217,91)",
    },
    {
      id: "Closed Lost",
      label: "Closed Lost",
      value: formatCurrencyLegend(closedLost),
      color: "rgb(196,69,54)",
    },
  ];

  const getDrawerData = async (params) => {
    setIsLoading(true);
    try {
      const response = await makeFormattedRequest(
        `crushr/analytics/lead_details.json?`,
        params
      );
      setDrawerData(response.data);
      setDisplayNumber(response.data.length);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
    setIsLoading(false);
  };

  const openDrawer = (e) => {
    let status = "";
    if (e.id === "Closed Won") {
      setDrawerTitle("Closed Won");
      status = "completed";
    } else {
      setDrawerTitle("Closed Lost");
      status = "failed";
    }
    const filtersForRequest = filters;
    filtersForRequest.status = status;
    filtersForRequest.lbCampaignId = undefined;
    getDrawerData(filtersForRequest);
    setIsDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Cell
        className="TopRowPieChartCell"
        title="Won vs. Lost"
        rowspan={1}
        colspan={1}
        color="#D0EABE"
        icon={<AnalyticsDollarGrassIcon width={44} height={44} />}
      >
        {pieData && (
          <>
            <PieChart
              width="320px"
              height="213px"
              data={pieData}
              onClick={(e) => {
                openDrawer(e);
              }}
              valueFormat=">-$,"
            />
            <Legend gap={4} entries={legendData} />
          </>
        )}
      </Cell>
      <LeadDetailsDrawer
        title={
          <DrawerHeaderWithNumber title={drawerTitle} number={displayNumber} />
        }
        data={drawerData}
        isLoading={isLoading}
        onClose={onDrawerClose}
        open={isDrawerOpen}
        showTags={false}
      />
    </>
  );
}

ClosedWonAndClosedLostCell.propTypes = {
  closed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
  failed: PropTypes.shape({
    current: PropTypes.string,
    previous: PropTypes.string,
  }),
  filters: PropTypes.object,
};

export default ClosedWonAndClosedLostCell;
