import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Cell, Leaderboard } from "@wingmate/graphs";
import { makeFormattedRequest } from "../../../utils/url";
import { LeadDetailsDrawer } from "./LeadDetailsDrawer/LeadDetailsDrawer";
import { DrawerHeaderWithNumber } from "./LeadDetailsDrawer/DrawerHeaderWithNumber";
import "./LeaderboardCell.scss";

export function LeaderboardCell({ filters = {}, leaderboard = [] }) {
  const [drawerData, setDrawerData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState();
  const [displayNumber, setDisplayNumber] = useState(null);

  const getDrawerData = async (params) => {
    setIsLoading(true);
    try {
      const response = await makeFormattedRequest(
        `crushr/analytics/lead_details.json?`,
        params
      );

      setDrawerData(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
    setIsLoading(false);
  };

  const showData = (target) => {
    const filtersForRequest = filters;
    filtersForRequest.lbCampaignId = [target];
    filtersForRequest.status = undefined;
    const targetName = leaderboard.find((leader) => leader.id === target);
    setCampaignTitle(targetName.name);
    const number = leaderboard.find((entry) => entry.id === target).value;

    setDisplayNumber(number);
    getDrawerData(filtersForRequest);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Cell
        className="LeaderboardCell"
        rowspan={2}
        colspan={1}
        position={{ col: 4, row: 1 }}
        title="Leaderboard"
      >
        <Leaderboard
          measurementLabel="Leads Generated"
          entryLabel="Pipelines"
          data={leaderboard}
          onClickEntry={(campaignId) => showData(campaignId)}
        />
      </Cell>

      <LeadDetailsDrawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        data={drawerData}
        isLoading={isLoading}
        title={
          <DrawerHeaderWithNumber
            number={displayNumber}
            title={campaignTitle}
          />
        }
      />
    </>
  );
}

LeaderboardCell.propTypes = {
  leaderboard: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageSrc: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  filters: PropTypes.object,
};

export default LeaderboardCell;
