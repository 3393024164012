import React from "react";
import { PropTypes } from "prop-types";
import { Cell, KeyIndicator } from "@wingmate/graphs";
import { AnalyticsFunnelSunriseIcon } from "@wingmate/graphics";

export function OpenCell({ open }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const countValue = open?.count || 0;
  const amountValue = open?.amount || 0;

  return (
    <Cell
      rowspan={1}
      colspan={1}
      icon={<AnalyticsFunnelSunriseIcon width={32} height={32} />}
      color="#FFECDD"
    >
      <KeyIndicator
        title="Open Pipeline"
        value={`${countValue} Leads`}
        trendValue={formatter.format(amountValue)}
        trendLabel="Value"
      />
    </Cell>
  );
}

OpenCell.propTypes = {
  open: PropTypes.shape({
    count: PropTypes.number,
    amount: PropTypes.string,
  }),
};

export default OpenCell;
